import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Helmet from "react-helmet";
import "./layout.css";

interface Props {
  description?: string;
  lang?: string;
  meta?: any[];
  title?: string;
  noindex?: boolean;
}

export const SEO: React.FC<Props> = ({
  description = "",
  lang = "en",
  meta = [],
  title,
  noindex,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  const realTitle = title || site.siteMetadata.title;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={realTitle}
    >
      {[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: realTitle,
        },
        {
          property: `og:image`,
          content: `https://www.mysaffronapp.com/twitter-image.png`,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: realTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(meta)
        .map(x => (
          <meta key={x.name || x.property || x.content} {...x} />
        ))}
      {noindex ? <meta name="robots" content="noindex" /> : null}
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="apple-itunes-app" content="app-id=1438683531" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/manifest.json" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#531410" />
      <meta name="theme-color" content="#531410" />
      <meta name="apple-mobile-web-app-status-bar-style" content="#4285f4" />
      <link
        href="https://fonts.googleapis.com/css?family=Lora:400,400i,700|Muli:300,400,600,700,800,900|Source+Sans+Pro:300,400,600,700,900"
        rel="stylesheet"
      />
    </Helmet>
  );
};
